import { useState, useEffect } from "react";
import {
  GlobalCard,
  useStyles,
  StepperComponent,
  WCInputFilter,
  WCInlineInputFilter,
  useWindowSize,
} from "@mfe/js-common-ave-uiutils";
import { FaCalendarDays } from "react-icons/fa6";
import { LuChevronDown } from "react-icons/lu";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { useForm } from "@mantine/form";
import {
  hourFormat,
  formatter,
  filterEmpty,
  lodash,
  parseDateWihtFormat,
  getAccessTokenApi,
  fetchFlightData,
  fetchCitiesData,
  fetchTraveLiquidatorByIDData,
  fetchTraveLiquidatorUpdateData,
  moment,
  getArrayByName,
  fetchFlightReservationDetail,
  extractTime,
  convertMinutes,
} from "@mfe/ts-common-ave-utilitaries";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import { CardFlightComponent } from "../components/cardFlight.component";

export default function BookFlight({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <BookFlightComponent organization={organization} />
    </MsalProvider>
  );
}

export const BookFlightComponent = ({ organization }) => {
  const { classes } = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const isSm = useWindowSize("sm");

  const [tokenApi, setTokenApi] = useState("");
  const { instance, accounts } = useMsal();
  const [pageNumber, setPageNumber] = useState(1);
  const [travelIndex, setTravelIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [priceValue, setPriceValue] = useState([]);
  const [travelTimeValue, setTravelTimeValue] = useState([0, 1410]);
  const [finalFlight, setFinalFlight] = useState(false);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [loadingWindow, setLoadingWindow] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [rates, setRates] = useState([]);

  const [data, setData] = useState({
    travelLiquidatorData: {
      ViajeTrayecto: [],
      TipoViajeAlias: "",
      TipoProceso: "",
      TipoViajeId: "",
      Multiviaje: false,
      CodigoEmpleadoViajero: "",
    },
    filterFlights: [],
    flightList: [],
    lowerRate: "",
    higherRate: "",
    travelDestinations: [],
  });

  const formTopFilter = useForm({
    initialValues: {
      originFilter: "",
      originSearchFilter: "",
      destinationFilter: "",
      destinationSearchFilter: "",
      datesFilter: [],
    },
  });

  const form = useForm({
    initialValues: {
      scale: [],
      nameAirline: [],
    },
  });

  const formSlider = useForm({
    initialValues: {
      prices: [],
      travelTime: [0, 1410],
    },
  });

  const nextFlightMap: any = data?.travelLiquidatorData?.ViajeTrayecto?.filter(
    (trip) =>
      trip?.ServicioBpo?.some((service) => service.AliasServicio === "AVION")
  )?.map((item, index) => ({
    ...item,
    TipoProceso: data?.travelLiquidatorData?.TipoProceso,
    TipoViajeAlias: data?.travelLiquidatorData?.TipoViajeAlias,
    FechaInicio: data?.travelLiquidatorData?.Multiviaje
      ? item.FechaInicio
      : index === 1
      ? item.FechaFin
      : item.FechaInicio,
  }));

  const travelDate = parseDateWihtFormat(
    nextFlightMap[travelIndex]?.FechaInicio,
    "YYYY-MM-DDT"
  );

  const filterMapped = {
    scale: form.values.scale,
    priceMin: priceValue[0],
    priceMax: priceValue[1],
    departureTimeMin: travelDate + hourFormat(travelTimeValue[0], "HH:mm:ss"),
    departureTimeMax: travelDate + hourFormat(travelTimeValue[1], "HH:mm:ss"),
    nameAirline: form.values.nameAirline,
  };

  const validateTravelType =
    String(data?.travelLiquidatorData?.TipoProceso) === "1" ||
    data?.travelLiquidatorData?.TipoViajeAlias !== "TVINTERNCL";

  const nextFlight = async (item) => {
    if (data?.travelLiquidatorData?.Multiviaje && !!Object.keys(item).length) {
      await fetchFlightReservationDetail(tokenApi, {
        flights: item,
        price: data?.travelLiquidatorData,
        rates: {
          lowerRate: data?.lowerRate,
          higherRate: data?.higherRate,
        },
      });
    } else {
      setRates((prev) => [
        ...prev,
        { lowerRate: data?.lowerRate, higherRate: data?.higherRate },
      ]);
    }
    setHasSearched(false);

    const newFlightMap = [...nextFlightMap];

    const validateEmpty = !Object.keys(item).length;

    const flightIndex = newFlightMap[travelIndex].ServicioBpo.findIndex(
      (servIndex) => servIndex.AliasServicio === "AVION"
    );

    validateEmpty
      ? (newFlightMap[travelIndex].ServicioBpo[flightIndex] = filterEmpty({
          ...newFlightMap[travelIndex].ServicioBpo[flightIndex],
        }))
      : (newFlightMap[travelIndex].ServicioBpo[flightIndex] = {
          ...newFlightMap[travelIndex].ServicioBpo[flightIndex],
          Valor: item?.fares?.totalAmount,
          nombreServicio: item?.nameAirline,
          imagenServicio: item?.image,
          descripcionServicio: JSON.stringify({
            ...item,
            isCheapest: item?.fares?.totalAmount === data?.lowerRate,
          }),
        });

    const updateData = {
      ...newFlightMap[travelIndex],
    };

    setSelectedFlights((prev) => [...prev, updateData]);

    const remainingIndexes = newFlightMap
      .slice(travelIndex + 1)
      .findIndex((servIndex) =>
        servIndex.ServicioBpo?.some(
          (service) => service.AliasServicio === "AVION"
        )
      );
    if (!validateTravelType) {
      setFinalFlight(true);
    } else {
      if (remainingIndexes !== -1) {
        setTravelIndex(travelIndex + 1);
      } else {
        setFinalFlight(true);
      }
    }
    form.reset();
    formSlider.setValues({
      prices: [],
      travelTime: [0, 1410],
    });
  };

  const updateOriginalList = (original, modified) => {
    modified.forEach((modificado) => {
      const indice = original.findIndex(
        (original) => original.Id === modificado.Id
      );

      if (indice !== -1) {
        original[indice] = modificado;
      }
    });

    return original;
  };

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    formSlider.setFieldValue("prices", [
      Number(data?.lowerRate),
      Number(data?.higherRate),
    ]);
    formTopFilter.setValues({
      datesFilter: [
        moment(
          hasSearched
            ? formTopFilter.values.datesFilter[0]
            : nextFlightMap[travelIndex]?.FechaInicio
        ).toDate(),
        moment(
          hasSearched
            ? formTopFilter.values.datesFilter[1]
            : nextFlightMap[travelIndex]?.FechaFin
        ).toDate(),
      ],
      originFilter: hasSearched
        ? formTopFilter.values.originFilter
        : [
            nextFlightMap[travelIndex]?.CodigoIataorigen,
            nextFlightMap[travelIndex]?.ciudadOrigenGrupo,
            nextFlightMap[travelIndex]?.nombreCiudadOrigen,
          ].join("/"),
      destinationFilter: hasSearched
        ? formTopFilter.values.destinationFilter
        : [
            nextFlightMap[travelIndex]?.CodigoIatadestino,
            nextFlightMap[travelIndex]?.ciudadDestinoGrupo,
            nextFlightMap[travelIndex]?.nombreCiudadDestino,
          ].join("/"),
    });
  }, [data?.lowerRate, data?.higherRate, travelIndex, hasSearched]);

  useEffect(() => {
    if (nextFlightMap.length) {
      const indice = nextFlightMap
        .map((item) => item.ServicioBpo)
        .findIndex((service) =>
          service.some((s) => s.AliasServicio === "AVION")
        );

      setTravelIndex(indice);
    }
  }, [data?.travelLiquidatorData]);

  useEffect(() => {
    if (travelIndex !== null) {
      fetchCitiesData(
        setData,
        tokenApi,
        data?.travelLiquidatorData?.TipoViajeId,
        parseDateWihtFormat(nextFlightMap[travelIndex]?.FechaFin, "YYYY-MM-DD"),
        false,
        () => {},
        () => {},
        () => {},
        false
      );
      if (pageNumber !== 1) {
        setPageNumber(1);
      }
      fetchFlightData(
        setData,
        setLoading,
        setError,
        tokenApi,
        1,
        nextFlightMap[travelIndex],
        filterMapped,
        formTopFilter.values,
        hasSearched,
        data?.travelLiquidatorData
      );
    }
  }, [form.values, priceValue, travelTimeValue, travelIndex]);

  useEffect(() => {
    if (pageNumber > 1) {
      fetchFlightData(
        setData,
        setLoading,
        setError,
        tokenApi,
        pageNumber,
        nextFlightMap[travelIndex],
        filterMapped,
        formTopFilter.values,
        hasSearched,
        data?.travelLiquidatorData
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        !data?.travelLiquidatorData?.Multiviaje &&
        selectedFlights.length > 0 &&
        selectedFlights
          ?.flatMap((flight) => flight.ServicioBpo)
          ?.filter((service) => service.Valor)?.length > 0
      ) {
        setLoadingWindow(true);
        await fetchFlightReservationDetail(tokenApi, {
          flights: selectedFlights,
          price: data?.travelLiquidatorData,
          rates,
        });
        setLoadingWindow(false);
      }
      fetchTraveLiquidatorUpdateData(
        tokenApi,
        {
          ...data?.travelLiquidatorData,
          ViajeTrayecto: updateOriginalList(
            data?.travelLiquidatorData?.ViajeTrayecto,
            selectedFlights
          ),
        },
        setLoadingWindow
      );
    };

    if (finalFlight) {
      fetchData();
    }
  }, [finalFlight]);

  const changeCityFlight = () => {
    setHasSearched(true);
    setError("");
    form.reset();
    formSlider.reset();
    fetchFlightData(
      setData,
      setLoading,
      setError,
      tokenApi,
      pageNumber,
      nextFlightMap[travelIndex],
      form.values,
      formTopFilter.values,
      true,
      data?.travelLiquidatorData
    );
  };

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const topFilterData = [
    {
      md: "auto",
      inputType: "select",
      placeHolder: "Origen",
      value: "originFilter",
      icon: <BiSolidPlaneAlt size="1.5rem" color="#004236" />,
      form: formTopFilter.getInputProps("originFilter"),
      data: lodash(data?.travelDestinations)
        .sortBy("label")
        .value()
        .filter(
          (city) => city.value !== formTopFilter.values.destinationFilter
        ),
      searchValue: formTopFilter.values.originSearchFilter,
      onSearchChange: (value) =>
        formTopFilter.setFieldValue("originSearchFilter", value),
      rightSection: <LuChevronDown size="1.2rem" color="#004236" />,
    },
    {
      md: "auto",
      inputType: "select",
      placeHolder: "Destino",
      value: "destinationFilter",
      icon: <BiSolidPlaneAlt size="1.5rem" color="#004236" />,
      form: formTopFilter.getInputProps("destinationFilter"),
      data: lodash(data?.travelDestinations)
        .sortBy("label")
        .value()
        .filter((city) => city.value !== formTopFilter.values.originFilter),
      searchValue: formTopFilter.values.destinationSearchFilter,
      onSearchChange: (value) =>
        formTopFilter.setFieldValue("destinationSearchFilter", value),
      rightSection: <LuChevronDown size="1.2rem" color="#004236" />,
    },
    {
      md: "auto",
      inputType: "dateInput",
      placeHolder: "Seleccionar Fechas",
      value: "datesFilter",
      icon: <FaCalendarDays size="1.2rem" color="#004236" />,
      form: {
        ...formTopFilter.getInputProps("datesFilter"),
        allowSingleDateInRange: true,
      },
    },
    {
      lg: 2,
      md: "content",
      inputType: "button",
      title: "Buscar",
      onClick: changeCityFlight,
    },
  ];

  const rightFilterData = [
    {
      title: "Precio",
      inputType: "slider",
      value: "prices",
      valueMin: Number(data?.lowerRate),
      valueMax: Number(data?.higherRate),
      onChangeEnd: setPriceValue,
      textMin: formatter.format(formSlider.values.prices[0]),
      textMax: formatter.format(formSlider.values.prices[1]),
      form: formSlider.getInputProps("prices"),
    },
    {
      title: "Hora vuelo",
      inputType: "slider",
      value: "travelTime",
      valueMin: 0,
      valueMax: 1410,
      minRange: 30,
      step: 30,
      onChangeEnd: setTravelTimeValue,
      textMin: hourFormat(formSlider.values.travelTime[0], "HH:mm"),
      textMax: hourFormat(formSlider.values.travelTime[1], "HH:mm"),
      form: formSlider.getInputProps("travelTime"),
    },
    {
      title: "Escalas",
      inputType: "checkbox",
      label: "scale",
      data:
        data?.filterFlights
          ?.find((item) => item?.scale)
          ?.scale?.map((s) => ({
            value: String(s),
            label: s === 0 ? "Directo" : `${s} ${s > 1 ? "Escalas" : "Escala"}`,
          })) || [],
      value: "scale",
      form: form.getInputProps("scale"),
    },
    {
      title: "Aerolíneas",
      inputType: "checkbox",
      label: "nameAirline",
      data: getArrayByName(data?.filterFlights, "nameAirline") || [],
      value: "nameAirline",
      form: form.getInputProps("nameAirline"),
    },
  ];

  const cityValidation = `${
    hasSearched
      ? formTopFilter.values.originFilter.split("/")[2]
      : nextFlightMap[travelIndex]?.nombreCiudadOrigen
  } - ${
    hasSearched
      ? formTopFilter.values.destinationFilter.split("/")[2]
      : nextFlightMap[travelIndex]?.nombreCiudadDestino
  }`;

  const rightButtonsFilterData = [
    {
      title: "Trayecto sin vuelo",
      inputType: "button",
      outline: true,
      onClick: () => nextFlight({}),
    },
  ];

  const utils = { formatter, extractTime, convertMinutes };

  const props = {
    classes,
    data,
    isSm,
    loading,
    error,
    utils,
    loadMore,
    cityValidation,
    rightFilterData,
    topFilterData,
    rightButtonsFilterData,
    loadingWindow,
    GlobalCard,
    WCInputFilter,
    StepperComponent,
    WCInlineInputFilter,
    nextFlight,
    validateTravelType,
  };

  return isAuthenticated ? <CardFlightComponent {...props} /> : null;
};
