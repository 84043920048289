import { useState } from "react";
import { Flex, Text, Grid, Divider, Button, Box, Image } from "@mantine/core";
import { FaPlane } from "react-icons/fa";
import { RiLuggageDepositFill } from "react-icons/ri";
import { MdHideImage } from "react-icons/md";
import { useDisclosure } from "@mantine/hooks";
import { FlightDetails } from "./flightDetail.component";

export const Flight = ({
  data,
  nextFlight,
  classes,
  isSm,
  GlobalCard,
  utils,
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  const [selectFlight, setSelectFlight] = useState({});
  const [errorImages, setErrorImages] = useState([]);

  const handleError = (index) => {
    const newImages = [...errorImages];
    newImages[index] = false;
    setErrorImages(newImages);
  };
  const flightDetailsProps = {
    selectFlight,
    opened,
    close,
    nextFlight,
    handleError,
    errorImages,
    setErrorImages,
    utils,
    classes,
  };

  return (
    <>
      {data?.map((item, index) => (
        <GlobalCard key={index}>
          <Grid align="center">
            <Grid.Col span="auto">
              {item.fligths.map((fligth, fligthIndex) => (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: fligthIndex > 0 ? null : isSm ? "auto" : "224px",
                  }}
                  key={fligthIndex}
                  mt={12}
                >
                  <Grid.Col xs={12} md="content">
                    {errorImages[fligthIndex] !== false ? (
                      <Image
                        src={fligth.image}
                        alt="logo"
                        className={classes.logoFligth}
                        onError={() => handleError(fligthIndex)}
                      />
                    ) : (
                      <Flex align="center" justify="center" h="100%">
                        <MdHideImage size="2rem" color="#004236" />
                      </Flex>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12} md="auto">
                    <Grid>
                      <Grid.Col lg={6} md="content">
                        <Text size="md">{fligth.departureCity}</Text>
                        <Text fw={700} size="md">
                          {utils.extractTime(fligth.departureDateTime)}
                        </Text>
                        <Text size="sm">{fligth.departureAirport}</Text>
                      </Grid.Col>
                      <Grid.Col lg={6} md="content">
                        <Text size="md">{fligth.arrivalCity}</Text>
                        <Text fw={700} size="md">
                          {utils.extractTime(fligth.arrivalDateTime)}
                        </Text>
                        <Text size="sm">{fligth.arrivalAirport}</Text>
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <Divider
                          my="md"
                          labelPosition="center"
                          style={{ margin: "0" }}
                          label={
                            <Flex align="center">
                              <FaPlane />
                              <Text ml={5}>
                                {utils.convertMinutes(+fligth.duration)}
                              </Text>
                              <Text c="Green">&nbsp; Directo</Text>
                            </Flex>
                          }
                        />
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                  <Grid.Col xs={12} md={2}>
                    <Flex
                      justify="center"
                      align="center"
                      direction="column"
                      wrap="wrap"
                    >
                      <RiLuggageDepositFill color="gray" />
                      <Text c="orange" size="sm">
                        {fligth.serviceCondition.baggageType}
                      </Text>
                      <Text c="orange" size="sm">
                        {fligth.serviceCondition.condition[1].value !==
                        "Economy"
                          ? fligth.serviceCondition.condition[1].value
                              .split(/(?=[A-Z])/)
                              .map((item, index) => (
                                <Text key={index}>{item}</Text>
                              ))
                          : fligth.serviceCondition.condition[1].value}
                      </Text>
                    </Flex>
                  </Grid.Col>
                </Grid>
              ))}
            </Grid.Col>
            <Divider orientation="vertical" />
            <Grid.Col xs={12} md={3}>
              <Box>
                <Text size="sm" fw={700} align="center" c="dimmed">
                  Valor total
                </Text>
                <Text size="xl" fw={700} c="#004236" align="center">
                  {utils.formatter.format(item.fares.totalAmount)}
                </Text>
                <Button
                  mb={15}
                  data-testid="select-flight-button"
                  fullWidth
                  onClick={() => nextFlight(item)}
                  className={classes.button}
                >
                  Seleccionar
                </Button>
                <Button
                  data-testid="show-details-button"
                  fullWidth
                  onClick={() => {
                    open();
                    setSelectFlight(item);
                  }}
                  className={classes.buttonOutline}
                >
                  Ver detalle
                </Button>
              </Box>
            </Grid.Col>
          </Grid>
        </GlobalCard>
      ))}
      <FlightDetails {...flightDetailsProps} />
    </>
  );
};
