import { Flight } from "../components/flight.component";
import { InternationalFlight } from "../components/internationalFlight.component";
import {
  Grid,
  Flex,
  Box,
  Button,
  Text,
  Center,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { AiFillWarning } from "react-icons/ai";
import { capitalize } from "lodash";
export const CardFlightComponent = ({
  classes,
  data,
  isSm,
  loading,
  error,
  utils,
  loadMore,
  cityValidation,
  rightFilterData,
  topFilterData,
  rightButtonsFilterData,
  loadingWindow,
  GlobalCard,
  WCInputFilter,
  StepperComponent,
  WCInlineInputFilter,
  nextFlight,
  validateTravelType,
}) => {
  const flightProps = {
    data: data?.flightList,
    nextFlight,
    classes,
    isSm,
    GlobalCard,
    utils,
  };

  const internationalFlightProps = {
    data: data?.flightList,
    nextFlight,
    classes,
    GlobalCard,
    utils,
  };

  if (loadingWindow) {
    return (
      <LoadingOverlay
        visible={loadingWindow}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }
  return (
    <>
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        <StepperComponent servicesData={data?.travelLiquidatorData} />
      </Box>
      {loading ? (
        <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
          <Grid.Col xs={12}>
            <GlobalCard>
              <Flex
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
                mih={isSm ? "auto" : "208px"}
              >
                <Text size="md" fw={700}>
                  Cargando vuelos disponibles en el Sistema de Distribución de
                  las Aerolíneas...
                </Text>
                <Loader variant="dots" color="#cbd300" />
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      ) : error.length > 0 ? (
        <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
          <Grid.Col xs={12}>
            <GlobalCard>
              <Flex
                gap={12}
                justify="center"
                align="center"
                direction="column"
                sx={{ height: isSm ? "auto" : "224px" }}
              >
                <Text fw={700} color="#004236" ta="center">
                  {`${capitalize(
                    error
                  )} para el trayecto de ${cityValidation}.`}
                </Text>
                <Button
                  className={classes.buttonOutline}
                  data-testid="skip-action-button"
                  onClick={() => nextFlight({})}
                >
                  Trayecto sin Vuelo
                </Button>
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      ) : (
        <>
          <WCInlineInputFilter inputs={topFilterData} scrollY="157" />
          <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
            <Grid.Col xs={12} md={3} orderSm={1} orderMd={2} orderLg={2}>
              <Box
                sx={{
                  position: "sticky",
                  top: 85,
                }}
              >
                <GlobalCard
                  styles={{
                    padding: "1.25rem .5rem",
                  }}
                >
                  <WCInputFilter
                    inputs={rightFilterData}
                    buttons={rightButtonsFilterData}
                  />
                </GlobalCard>
              </Box>
            </Grid.Col>
            <Grid.Col xs={12} md={9} orderSm={2} orderMd={1} orderLg={1}>
              <GlobalCard>
                <Grid>
                  <Grid.Col span="content">
                    <AiFillWarning
                      size="2rem"
                      style={{
                        color: "#004236",
                        marginRight: "0.5rem",
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span="auto">
                    <Text size="sm">
                      Tenga en cuenta que la aprobación de su viaje debe
                      realizar el día de hoy antes de las 11:40 pm, de lo
                      contrario la reserva será cancelada automáticamente por el
                      sistema de reservas de las aerolíneas.
                    </Text>
                  </Grid.Col>
                </Grid>
              </GlobalCard>
              <Flex align="center" gap="8px" pt="16px">
                <BiSolidPlaneAlt
                  color="#004236"
                  size="24px"
                  style={{ flexShrink: 0 }}
                />
                <Text size="xl" role="heading" fw={700} color="#004236">
                  {`Vuelos disponibles para el viaje de ${cityValidation}`}
                </Text>
              </Flex>
              <Box>
                {!validateTravelType ? (
                  <InternationalFlight {...internationalFlightProps} />
                ) : (
                  <Flight {...flightProps} />
                )}
              </Box>
              <Center h={100}>
                <Box>
                  <Button className={classes.buttonOutline} onClick={loadMore}>
                    Cargar más
                  </Button>
                </Box>
              </Center>
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
};
