import { Divider, Drawer, Flex, Grid, Text, Image } from "@mantine/core";
import { FaPlane } from "react-icons/fa";
import { MdHideImage } from "react-icons/md";
import { SelectFlight } from "../components/selectFlight.component";

export const FlightDetails = ({
  selectFlight,
  opened,
  close,
  nextFlight,
  handleError,
  errorImages,
  setErrorImages,
  utils,
  classes,
}) => {
  const handleClose = () => {
    close();
    setErrorImages([]);
  };

  const selecFlightProps = {
    selectFlight,
    utils,
    classes,
    nextFlight,
    close,
  };
  return (
    <Drawer
      opened={opened}
      onClose={handleClose}
      padding="md"
      size="md"
      overlayProps={{ opacity: 0.5, blur: 4 }}
      zIndex={300}
    >
      <Grid mb={60}>
        <Grid.Col span={12} my="0">
          <Text fw={700} size="lg" c="#004236">
            Resumen Del Vuelo
          </Text>
          {selectFlight?.fligths?.map((item, index) => (
            <div key={index}>
              <Flex align="center">
                <Grid.Col span={6}>
                  {errorImages[index] !== false ? (
                    <Image
                      src={item.image}
                      alt="logo"
                      className={classes.logoFligth}
                      onError={() => handleError(index)}
                    />
                  ) : (
                    <Flex align="center" justify="center" h="100%">
                      <MdHideImage size="2rem" color="#004236" />
                    </Flex>
                  )}
                </Grid.Col>
              </Flex>
              <Flex wrap="wrap" align="center">
                <Grid.Col span={6}>
                  <Text fw={700} size="md" c="#004236">
                    {item.departureCity}
                  </Text>
                  <Text fw={700} size="md" c="#004236">
                    {utils.extractTime(item.departureDateTime)}
                  </Text>
                  <Text size="sm">{item.departureAirport}</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text fw={700} size="md" c="#004236">
                    {item.arrivalCity}
                  </Text>
                  <Text fw={700} size="md" c="#004236">
                    {utils.extractTime(item.arrivalDateTime)}
                  </Text>
                  <Text size="sm">{item.arrivalAirport}</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="md" fw={700} c="#004236">
                    Equipaje:
                    <Text span c="black" fw={500} inherit>
                      {` ${item.serviceCondition.baggageType}`}
                    </Text>
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="md" fw={700} c="#004236">
                    Vuelo Nº:
                    <Text span c="black" fw={500} inherit>
                      {` ${item.flightNumber}`}
                    </Text>
                  </Text>
                  <Text size="sm" fw={700}></Text>
                </Grid.Col>
              </Flex>
              <Divider
                my="md"
                labelPosition="center"
                style={{ margin: "0" }}
                label={
                  <Flex align="center">
                    <FaPlane />
                    <Text ml={5}> {utils.convertMinutes(+item.duration)}</Text>
                    <Text c="Green">&nbsp; Directo</Text>
                  </Flex>
                }
              />
            </div>
          ))}
        </Grid.Col>
        <SelectFlight {...selecFlightProps} />
      </Grid>
    </Drawer>
  );
};
