import { useState } from "react";

import { Flex, Text, Grid, Divider, Button, Box, Badge } from "@mantine/core";
import { FaPlane } from "react-icons/fa";
import { RiLuggageDepositFill } from "react-icons/ri";
import { useDisclosure } from "@mantine/hooks";
import { InternationalFlightDetails } from "./internationalFlightDetail.component";

export const InternationalFlight = ({
  data,
  nextFlight,
  classes,
  GlobalCard,
  utils,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectFlight, setSelectFlight] = useState({});

  const internationalFlightDetailsProps = {
    selectFlight,
    opened,
    close,
    nextFlight,
    utils,
    classes,
  };
  return (
    <>
      {data?.map((item, index) =>
        item.returnsFlight.map((returnsFlight, returnsFlightIndex) => (
          <GlobalCard key={index}>
            <Badge
              sx={{
                backgroundColor: "#cbd300",
                color: "#004236",
                fontWeight: "bolder",
              }}
            >
              {`Ida ${
                returnsFlight.flightSegments.length - 1 === 0
                  ? "Directo"
                  : `${returnsFlight.flightSegments.length - 1} escala(S)`
              } `}
            </Badge>
            <Grid align="center">
              <Grid.Col span="auto">
                {item.departureFlight.flightSegments.map(
                  (departureFlight, departureFlightIndex) => (
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={departureFlightIndex}
                      mt={12}
                    >
                      <Grid.Col xs={12} md="content">
                        <img
                          src={departureFlight.image}
                          alt="logo"
                          className={classes.logoFligth}
                        />
                      </Grid.Col>
                      <Grid.Col xs={12} md="auto">
                        <Grid>
                          <Grid.Col lg={6} md="content">
                            <Text size="md">
                              {departureFlight.departureCity}
                            </Text>
                            <Text fw={700} size="md">
                              {utils.extractTime(
                                departureFlight.departureDateTime
                              )}
                            </Text>
                            <Text size="sm">
                              {departureFlight.departureAirport}
                            </Text>
                          </Grid.Col>
                          <Grid.Col lg={6} md="content">
                            <Text size="md">{departureFlight.arrivalCity}</Text>
                            <Text fw={700} size="md">
                              {utils.extractTime(
                                departureFlight.arrivalDateTime
                              )}
                            </Text>
                            <Text size="sm">
                              {departureFlight.arrivalAirport}
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={12}>
                            <Divider
                              my="md"
                              labelPosition="center"
                              style={{ margin: "0" }}
                              label={
                                <Flex align="center">
                                  <FaPlane />
                                  <Text ml={5}>
                                    {utils.convertMinutes(
                                      +departureFlight.duration
                                    )}
                                  </Text>
                                  <Text c="Green">&nbsp; Directo</Text>
                                </Flex>
                              }
                            />
                          </Grid.Col>
                        </Grid>
                      </Grid.Col>
                      <Grid.Col xs={12} md={2}>
                        <Flex
                          justify="center"
                          align="center"
                          direction="column"
                          wrap="wrap"
                        >
                          <RiLuggageDepositFill color="gray" />
                          <Text c="orange" size="sm">
                            {departureFlight.serviceCondition.baggageType}
                          </Text>
                          <Text c="orange" size="sm">
                            {
                              departureFlight.serviceCondition.condition[1]
                                .value
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  )
                )}

                <Badge
                  sx={{
                    backgroundColor: "#cbd300",
                    color: "#004236",
                    fontWeight: "bolder",
                  }}
                >
                  {`Regreso ${
                    item.departureFlight.flightSegments.length - 1 === 0
                      ? "Directo"
                      : `${
                          item.departureFlight.flightSegments.length - 1
                        } escala(S)`
                  } `}
                </Badge>

                {returnsFlight.flightSegments.map(
                  (returnsFlight, returnsFlightIndex) => (
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={returnsFlightIndex}
                      mt={12}
                    >
                      <Grid.Col xs={12} md="content">
                        <img
                          src={returnsFlight.image}
                          alt="logo"
                          className={classes.logoFligth}
                        />
                      </Grid.Col>
                      <Grid.Col xs={12} md="auto">
                        <Grid>
                          <Grid.Col lg={6} md="content">
                            <Text size="md">{returnsFlight.departureCity}</Text>
                            <Text fw={700} size="md">
                              {utils.extractTime(
                                returnsFlight.departureDateTime
                              )}
                            </Text>
                            <Text size="sm">
                              {returnsFlight.departureAirport}
                            </Text>
                          </Grid.Col>
                          <Grid.Col lg={6} md="content">
                            <Text size="md">{returnsFlight.arrivalCity}</Text>
                            <Text fw={700} size="md">
                              {utils.extractTime(returnsFlight.arrivalDateTime)}
                            </Text>
                            <Text size="sm">
                              {returnsFlight.arrivalAirport}
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={12}>
                            <Divider
                              my="md"
                              labelPosition="center"
                              style={{ margin: "0" }}
                              label={
                                <Flex align="center">
                                  <FaPlane />
                                  <Text ml={5}>
                                    {utils.convertMinutes(
                                      +returnsFlight.duration
                                    )}
                                  </Text>
                                  <Text c="Green">&nbsp; Directo</Text>
                                </Flex>
                              }
                            />
                          </Grid.Col>
                        </Grid>
                      </Grid.Col>
                      <Grid.Col xs={12} md={2}>
                        <Flex
                          justify="center"
                          align="center"
                          direction="column"
                          wrap="wrap"
                        >
                          <RiLuggageDepositFill color="gray" />
                          <Text c="orange" size="sm">
                            {returnsFlight.serviceCondition.baggageType}
                          </Text>
                          <Text c="orange" size="sm">
                            {returnsFlight.serviceCondition.condition[1].value}
                          </Text>
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  )
                )}
              </Grid.Col>

              <Divider orientation="vertical" />

              <Grid.Col xs={12} md={3}>
                <Box>
                  <Text size="sm" fw={700} align="center" c="dimmed">
                    Valor total
                  </Text>
                  <Text size="xl" fw={700} c="#004236" align="center">
                    {utils.formatter.format(item.fares.totalAmount)}
                  </Text>

                  <Button
                    mb={15}
                    data-testid="select-flight-button"
                    fullWidth
                    onClick={() =>
                      nextFlight({
                        fares: item.fares,
                        returnsFlight: returnsFlight,
                        departureFlight: item.departureFlight,
                        id: item.id,
                      })
                    }
                    className={classes.button}
                  >
                    Seleccionar
                  </Button>
                  <Button
                    data-testid="show-details-button"
                    fullWidth
                    onClick={() => {
                      open();
                      setSelectFlight({
                        ...item,
                        returnsFlight: returnsFlight,
                        departureFlight: item.departureFlight,
                      });
                    }}
                    className={classes.buttonOutline}
                  >
                    Ver detalle
                  </Button>
                </Box>
              </Grid.Col>
            </Grid>
          </GlobalCard>
        ))
      )}
      <InternationalFlightDetails {...internationalFlightDetailsProps} />
    </>
  );
};
