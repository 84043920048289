import { Grid, Text, Button } from "@mantine/core";

export const SelectFlight = ({
  selectFlight,
  utils,
  classes,
  nextFlight,
  close,
}) => {
  const handleClick = () => {
    nextFlight(selectFlight);
    close();
  };

  return (
    <>
      <Grid.Col span={12}>
        <Grid.Col span={12}>
          <Text size="md" fw={700} c="#004236" ta="center">
            Duración Total del vuelo:
            <Text span c="black" fw={500} inherit>
              {` ${utils.convertMinutes(selectFlight?.durationTotal)}`}
            </Text>
          </Text>
          <Text size="sm" fw={700}></Text>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col span={12}>
        <Text size="xl" fw={700} c="#004236" ta="center" mb={10}>
          Total: {utils.formatter.format(selectFlight?.fares?.totalAmount)}
        </Text>
        <Text size="md" c="#004236" ta="center" mb={10}>
          Tipo base:
          <Text span fw="bold">
            {` ${utils.formatter.format(selectFlight?.fares?.baseAmount)}`}
          </Text>
        </Text>
        <Text size="md" c="#004236" ta="center" mb={10}>
          Tipo base:
          <Text span fw="bold">
            {` ${utils.formatter.format(selectFlight?.fares?.taxesAmount)}`}
          </Text>
        </Text>
        <Text size="md" c="#004236" ta="center" mb={10}>
          Tipo base:
          <Text span fw="bold">
            {` ${utils.formatter.format(
              selectFlight?.fares?.administrativeAmount
            )}`}
          </Text>
        </Text>
        <Button
          data-testid="hide-details-button"
          fullWidth
          className={classes.button}
          onClick={handleClick}
        >
          Seleccionar
        </Button>
      </Grid.Col>
    </>
  );
};
