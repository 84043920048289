import { Badge, Divider, Drawer, Flex, Grid, Text } from "@mantine/core";
import { FaPlane } from "react-icons/fa";
import { SelectFlight } from "../components/selectFlight.component";

export const InternationalFlightDetails = ({
  selectFlight,
  opened,
  close,
  nextFlight,
  utils,
  classes,
}) => {
  const selecFlightProps = {
    selectFlight,
    utils,
    classes,
    nextFlight,
    close,
  };

  const translateCabina = {
    Economy: "Económica",
    Business: "Ejecutiva",
    PremiumEconomy: "Premium Económica",
  };

  return (
    <Drawer
      opened={opened}
      onClose={close}
      padding="md"
      size="md"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <Grid mb={60}>
        <Grid.Col span={12} my="0">
          <Text fw={700} size="lg" c="#004236">
            Resumen Del Vuelo
          </Text>
          <Badge
            sx={{
              backgroundColor: "#cbd300",
              color: "#004236",
              fontWeight: "bolder",
            }}
          >
            Ida
          </Badge>
          {selectFlight?.departureFlight?.flightSegments?.map(
            (departureFlight, departureFlightIndex) => (
              <div key={departureFlightIndex}>
                <Grid.Col>
                  <Flex align="center">
                    <img
                      src={departureFlight.image}
                      alt="logo"
                      className={classes.logoFligth}
                    />
                    <Text fw={700} size="md" c="#004236">
                      {departureFlight?.nameAirLine}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Flex wrap="wrap" align="center">
                  <Grid.Col span={6}>
                    <Text fw={700} size="md" c="#004236">
                      {departureFlight.departureCity}
                    </Text>
                    <Text fw={700} size="md" c="#004236">
                      {utils.extractTime(departureFlight.departureDateTime)}
                    </Text>
                    <Text size="sm">{departureFlight.departureAirport}</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Text fw={700} size="md" c="#004236">
                      {departureFlight.arrivalCity}
                    </Text>
                    <Text fw={700} size="md" c="#004236">
                      {utils.extractTime(departureFlight.arrivalDateTime)}
                    </Text>
                    <Text size="sm">{departureFlight.arrivalAirport}</Text>
                  </Grid.Col>
                  <Grid.Col>
                    <Divider
                      my="md"
                      labelPosition="center"
                      style={{ margin: "0" }}
                      label={
                        <Flex align="center">
                          <FaPlane />
                          <Text ml={5}>
                            {utils.convertMinutes(+departureFlight.duration)}
                          </Text>
                        </Flex>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col>
                    <Text size="md" fw={700} c="#004236">
                      Avión:
                      <Text span c="black" fw={500} inherit>
                        {` ${departureFlight.equipment}`}
                      </Text>
                    </Text>
                    <Text size="md" fw={700} c="#004236">
                      Vuelo Nº:
                      <Text span c="black" fw={500} inherit>
                        {` ${departureFlight?.codeAirLine}${departureFlight.flightNumber}`}
                      </Text>
                    </Text>
                    <Text size="md" fw={700} c="#004236">
                      Cabina:
                      <Text span c="black" fw={500} inherit>
                        {` ${
                          translateCabina[departureFlight?.cabinType] ||
                          departureFlight?.cabinType
                        }`}
                      </Text>
                    </Text>
                    <Text size="md" fw={700} c="#004236">
                      Clase:
                      <Text span c="black" fw={500} inherit>
                        {` ${departureFlight?.classAvail}`}
                      </Text>
                    </Text>
                    <Text size="md" fw={700} c="#004236">
                      Vuelo operado por:
                      <Text span c="black" fw={500} inherit>
                        {` ${departureFlight?.nameOperatingAirLine}`}
                      </Text>
                    </Text>
                  </Grid.Col>
                  {departureFlightIndex <
                    selectFlight?.departureFlight?.flightSegments?.length -
                      1 && (
                    <Grid.Col bg="rgba(0, 66, 54, .1)" p={10}>
                      <Text fw={"bold"} color="#004236">
                        Escala en: {departureFlight?.arrivalAirport}
                      </Text>
                    </Grid.Col>
                  )}
                </Flex>
              </div>
            )
          )}
          <Badge
            sx={{
              backgroundColor: "#cbd300",
              color: "#004236",
              fontWeight: "bolder",
            }}
            mt={10}
          >
            Regreso
          </Badge>
          {selectFlight?.returnsFlight?.flightSegments?.map(
            (returnsFlight, returnsFlightIndex) => (
              <div key={returnsFlightIndex}>
                <Grid.Col>
                  <Flex align="center">
                    <img
                      src={returnsFlight.image}
                      alt="logo"
                      className={classes.logoFligth}
                    />
                    <Text fw={700} size="md" c="#004236">
                      {returnsFlight?.nameAirLine}
                    </Text>
                  </Flex>
                </Grid.Col>
                <Flex wrap="wrap" align="center">
                  <Grid.Col span={6}>
                    <Text fw={700} size="md" c="#004236">
                      {returnsFlight.departureCity}
                    </Text>
                    <Text fw={700} size="md" c="#004236">
                      {utils.extractTime(returnsFlight.departureDateTime)}
                    </Text>
                    <Text size="sm">{returnsFlight.departureAirport}</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Text fw={700} size="md" c="#004236">
                      {returnsFlight.arrivalCity}
                    </Text>
                    <Text fw={700} size="md" c="#004236">
                      {utils.extractTime(returnsFlight.arrivalDateTime)}
                    </Text>
                    <Text size="sm">{returnsFlight.arrivalAirport}</Text>
                  </Grid.Col>
                  <Grid.Col>
                    <Divider
                      my="md"
                      labelPosition="center"
                      style={{ margin: "0" }}
                      label={
                        <Flex align="center">
                          <FaPlane />
                          <Text ml={5}>
                            {utils.convertMinutes(+returnsFlight.duration)}
                          </Text>
                        </Flex>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col>
                    <Text size="md" fw={700} c="#004236">
                      Avión:
                      <Text span c="black" fw={500} inherit>
                        {` ${returnsFlight.equipment}`}
                      </Text>
                    </Text>
                    <Text size="md" fw={700} c="#004236">
                      Vuelo Nº:
                      <Text span c="black" fw={500} inherit>
                        {` ${returnsFlight?.codeAirLine}${returnsFlight.flightNumber}`}
                      </Text>
                    </Text>
                    <Text size="md" fw={700} c="#004236">
                      Cabina:
                      <Text span c="black" fw={500} inherit>
                        {` ${
                          translateCabina[returnsFlight?.cabinType] ||
                          returnsFlight?.cabinType
                        }`}
                      </Text>
                    </Text>
                    <Text size="md" fw={700} c="#004236">
                      Clase:
                      <Text span c="black" fw={500} inherit>
                        {` ${returnsFlight?.classAvail}`}
                      </Text>
                    </Text>
                    <Text size="md" fw={700} c="#004236">
                      Vuelo operado por:
                      <Text span c="black" fw={500} inherit>
                        {` ${returnsFlight?.nameOperatingAirLine}`}
                      </Text>
                    </Text>
                  </Grid.Col>
                  {returnsFlightIndex <
                    selectFlight?.returnsFlight?.flightSegments?.length - 1 && (
                    <Grid.Col bg="rgba(0, 66, 54, .1)" p={10}>
                      <Text fw={"bold"} color="#004236">
                        Escala en: {returnsFlight?.arrivalAirport}
                      </Text>
                    </Grid.Col>
                  )}
                </Flex>
              </div>
            )
          )}
        </Grid.Col>
        <SelectFlight {...selecFlightProps} />
      </Grid>
    </Drawer>
  );
};
